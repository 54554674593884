.card {
  border-radius: 0.5rem;
  background-color: $white;
  padding: 1rem;
  border: unset;
  transition: all 0.2s ease-in-out;
  &__shadow {
    box-shadow: 0px 4px 4px rgba(43, 57, 202, 0.08);

    &-02 {
      box-shadow: 0px -4px 12px rgba(43, 57, 202, 0.08),
        0px 4px 4px rgba(30, 40, 142, 0.04);
    }
  }
  &__bordered {
    border: 1px solid $gray-88;
  }
  &__hover {
    transform: translateY(-8px);
    box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.08);
  }
  &__rounded {
    border-radius: 0.5rem;
  }

  &-01 {
    border-radius: 0.5rem;
    padding: 1.5rem;
    text-align: center;
    @include flex(center, center, column);
    height: 100%;
    background-color: $white;
    .img {
      width: 7.5rem;
      height: 7.5rem;
      border-radius: 50%;
      flex-grow: 1;

      img {
        object-position: top;
      }
    }
  }
  &-02 {
    @extend .card;
    border-radius: 0.5rem;
    padding: 1rem;
    &:hover {
      @extend .card__hover;
    }
  }
  &-03 {
    .img-landscape {
      border-radius: 0.5rem 0.5rem 0 0;
    }
    .card-body {
      @extend .card__bordered;
      padding: 1rem;
      border-radius: 0 0 0.5rem 0.5rem;
      position: relative;
      display: flex;
      flex-direction: column;
      .font-semibold {
        margin: 0.75rem 0 0.25rem 0;
        flex-grow: 1;
      }
      &::before {
        content: " ";
        width: 2.5rem;
        height: 0.25rem;
        background-color: $primary;
        position: absolute;
      }
      &::after {
        content: " ";
        width: 2.5rem;
        height: 0.25rem;
        background-color: $secondary;
        position: absolute;
        left: 3.75rem;
        top: 1rem;
      }
    }
    img {
      transition: all 0.2s ease-in-out;
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
  &-bod {
    margin-top: 35%;
    @include media-breakpoint-down(sm) {
      margin-top: 25%;
      padding-top: 25%;
    }
    cursor: pointer;
  }

  &-faq {
    padding: 1rem;
    .card-header {
      padding: unset;
      background-color: $white;
      border-bottom: unset;
      cursor: pointer;
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
      border-bottom: 1px solid $gray-88;
    }
  }

  &-report {
    padding: 1rem;
    border-radius: 0.25rem;
    .card {
      &-header {
        margin: -1rem;
        cursor: pointer;
      }
    }

    &:not(:last-child) {
      margin-bottom: 0.75rem;
    }

    .list {
      padding-top: 1rem;
      li {
        margin-top: 1rem;
        padding: 0.75rem;

        a {
          .icon {
            width: 2rem;
            height: 2rem;
            i {
              font-size: 1.125rem;
            }
          }
          &:hover {
            p {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
.rdw-editor-wrapper{
  border: 1px solid #d8d8d8;
}