.dropdown {
  @include media-breakpoint-down(lg) {
    @include flex(center, center, column);
  }
  &-menu {
    padding: 0;
    border: 0;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.16);
    border-radius: 0;
    &:before {
      content: "\e908";
      font-family: "icomoon";
      color: white;
      @include rfs(16);
      position: absolute;
      top: -1rem;
      right: 2rem;
    }

    > a {
      @include rfs(14);
      @include hover {
        color: $secondary;
      }
      &.active {
        color: $secondary;
      }
      &:hover,
      &:focus,
      &:active {
        background-color: white;
        color: $secondary;
      }
    }
    @include media-breakpoint-down(md) {
      position: absolute;
    }
    .inner {
      position: relative;
      > a {
        padding-right: 2.5rem;
        &::before {
          content: "\e907";
          font-family: icomoon;
          @include rfs(14);
          position: absolute;
          right: 0.25rem;
          color: $gray-56;
        }
      }

      &:hover {
        > a {
          background-color: $primary;
          color: $white;
          &::before {
            color: $white;
          }
        }
        .dropdown-menu-inner {
          display: block;
          @include media-breakpoint-up(lg) {
            position: absolute;
            top: 0;
            left: 100%;
            box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
            margin-left: 0;
          }
          background-color: $white;
          margin-left: 1rem;
        }
      }
    }

    &-inner {
      display: none;
    }
  }
  &-toggle {
    &::after {
      content: "\e905";
      font-family: "icomoon";
      border: none;
      margin-left: 0.25rem;
      vertical-align: middle;
      @include media-breakpoint-down(md) {
        color: unset;
      }
    }
  }
  &-item {
    padding: 0.75rem 1.5rem;
    @include rfs(14);
    &:hover,
    &:focus,
    &:active {
      background-color: white;
      color: $secondary;
      position: relative;
      // font-weight: 500;
      // &::before {
      //   content: "";
      //   width: 0.375rem;
      //   height: 100%;
      //   position: absolute;
      //   left: 0;
      //   top: 50%;
      //   transform: translateY(-50%);
      //   background-color: $secondary;
      // }
    }
    &.active {
      color: $secondary;
      position: relative;
      font-weight: 500;
      background-color: white;
      &::before {
        content: "";
        width: 0.375rem;
        height: 100%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: $secondary;
      }
    }
  }
}
