@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?x04s7p');
  src:  url('fonts/icomoon.eot?x04s7p#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?x04s7p') format('truetype'),
    url('fonts/icomoon.woff?x04s7p') format('woff'),
    url('fonts/icomoon.svg?x04s7p#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ic-"], [class*=" ic-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ic-calendar-date:before {
  content: "\e935";
}
.ic-briefcase:before {
  content: "\e932";
}
.ic-clock:before {
  content: "\e933";
}
.ic-upload:before {
  content: "\e934";
}
.ic-youtube:before {
  content: "\e922";
}
.ic-chevron-bottom:before {
  content: "\e923";
}
.ic-chevron-left:before {
  content: "\e924";
}
.ic-chevron-right:before {
  content: "\e925";
}
.ic-chevron-top:before {
  content: "\e926";
}
.ic-diamond:before {
  content: "\e927";
}
.ic-download:before {
  content: "\e928";
}
.ic-eye:before {
  content: "\e929";
}
.ic-gear:before {
  content: "\e92a";
}
.ic-gmail:before {
  content: "\e92b";
}
.ic-instagram:before {
  content: "\e92c";
}
.ic-link:before {
  content: "\e92d";
}
.ic-location:before {
  content: "\e92e";
}
.ic-point:before {
  content: "\e92f";
}
.ic-rocket:before {
  content: "\e930";
}
.ic-twitter:before {
  content: "\e931";
}
.ic-calculator:before {
  content: "\e921";
}
.ic-close:before {
  content: "\e920";
}
.ic-call:before {
  content: "\e91a";
}
.ic-fb:before {
  content: "\e91b";
}
.ic-linkedin:before {
  content: "\e91c";
}
.ic-location1:before {
  content: "\e91d";
}
.ic-mail:before {
  content: "\e91e";
}
.ic-twitter1:before {
  content: "\e91f";
}
.ic-quote:before {
  content: "\e914";
}
.ic-arrow-down:before {
  content: "\e900";
}
.ic-arrow-left:before {
  content: "\e901";
}
.ic-arrow-right:before {
  content: "\e902";
}
.ic-arrow-top:before {
  content: "\e903";
}
.ic-bank:before {
  content: "\e904";
}
.ic-caret-bottom:before {
  content: "\e905";
}
.ic-caret-left:before {
  content: "\e906";
}
.ic-caret-right:before {
  content: "\e907";
}
.ic-caret-top:before {
  content: "\e908";
}
.ic-chat:before {
  content: "\e909";
}
.ic-coins:before {
  content: "\e90a";
}
.ic-download1:before {
  content: "\e90b";
}
.ic-fund:before {
  content: "\e90c";
}
.ic-growth:before {
  content: "\e90d";
}
.ic-home:before {
  content: "\e90e";
}
.ic-login:before {
  content: "\e90f";
}
.ic-management:before {
  content: "\e910";
}
.ic-menu:before {
  content: "\e911";
}
.ic-news:before {
  content: "\e912";
}
.ic-notice:before {
  content: "\e913";
}
.ic-safe:before {
  content: "\e915";
}
.ic-search:before {
  content: "\e916";
}
.ic-star:before {
  content: "\e917";
}
.ic-team:before {
  content: "\e918";
}
.ic-user:before {
  content: "\e919";
}
