// hero
.section {
  &-about {
    position: relative;
    .card-01 {
      z-index: 2;
    }
    .svg-box {
      z-index: -99;
      bottom: 0;
    }
  }

  &-who {
    margin-bottom: 3rem;
  }

  &-project {
    position: relative;
    .position-sticky {
      top: 140px;
    }
    .textbox {
      p {
        text-align: justify;
      }
    }
  }

  &-download {
    .download {
      &-item {
        display: flex;
        .count {
          width: 4rem;
        }

        &:hover {
          background-color: $gray-96;
          .count {
            background-color: $secondary !important;
          }
          .icon {
            background-color: $secondary !important;
            i {
              color: $white !important;
            }
          }
        }

        &:not(:last-child) {
          margin-bottom: 1.5rem;
        }
      }
    }
    .card {
      position: sticky;
      top: 11rem;
    }
  }

  &-career {
    .card__shadow {
      @include flex(center, space-between, row);
    }

    .list {
      &-separator {
        @include media-breakpoint-down(sm) {
          flex-direction: column;
          li {
            margin: 0 0 0.75rem 0;
            padding: unset;
            border: unset;
          }
        }
      }
    }
  }

  &-report {
    .position-sticky {
      top: 140px;
    }
  }

  &-gallery {
  }
  &-search {
    .result {
      &:not(:last-child) {
        border-bottom: 1px solid $gray-88;
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
      }
    }
  }
}

.sideicons {
  &-wrapper {
    position: fixed;
    top: 50%;
    right: 0;
    z-index: 9;
    transform: translateY(-50%) translateX(calc(100% - 3rem));
    @include media-breakpoint-down(md) {
      display: none;
    }
    li {
      background-color: white;
      padding: 0.75rem;
      width: 3rem;
      max-height: 3rem;
      overflow: hidden;
      border-radius: 8px 0px 0px 8px;
      filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.16));
      cursor: pointer;
      transform-origin: 100% 100%;
      [class^="ic-"],
      [class*=" ic-"] {
        color: $primary;
        @include rfs(26);
      }

      > a {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      @include hover {
        background-color: $primary;
        width: 100%;
        transition: width 0.5s $time-func-1;
        transform: translateX(calc(-100% + 3rem));
        .title {
          color: white;
          transform: translateX(0);
          transition: transform 0.5s $time-func-1, opacity 1s $time-func-1;
          opacity: 1;
        }
        [class^="ic-"],
        [class*=" ic-"] {
          color: white;
        }
      }
      &:not(:last-child) {
        margin-bottom: 0.25rem;
      }
    }
    .title {
      margin-left: 0.75rem;
      transform: translateX(-190%);
      opacity: 0;
      white-space: nowrap;
    }
  }
}

.scroll {
  &-section {
    &:not(:first-child) {
      @include media-breakpoint-up(lg) {
        // @include flex(center, center, column);
        // padding-top: 7rem;
      }
      @include media-breakpoint-down(md) {
        height: auto !important;
      }
    }
  }
}

.news {
  &-highlights {
    margin-bottom: 1rem;
    display: flex;
    .media-wrapper {
      flex-shrink: 0;
      width: 6rem;
      border-radius: 0.5rem;
      margin-right: 1rem;
    }
    h1,
    p {
      @include clamp(2);
    }
  }
}

.justify-content{
  text-align: justify;
}