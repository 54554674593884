.list {
  @include list-unstyled;
  padding: 0;
  margin: 0;

  &__inline {
    display: flex;

    li {
      &:not(:last-child) {
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &-dashed {
    @include rfs(12);
    color: $black;
    > li {
      &:not(:last-child) {
        margin-bottom: 0.75rem;
      }
      .bordered {
        height: 1px;
        border-bottom: 1px dashed $gray-88;
        margin: 0 0.25rem;
        flex-grow: 1;
        min-width: 1rem;
      }
    }
    p {
      @include rfs(12);
    }
  }

  &-dot {
    display: flex;
    li {
      &:not(:last-child) {
        position: relative;
        margin-right: 0.75rem;
        padding-right: 0.75rem;

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: -0.125rem;
          transform: translateY(-50%);
          width: 4px;
          height: 4px;
          background-color: $gray-72;
          border-radius: 50%;
        }
      }
    }
  }
  &-bullets {
    li {
      position: relative;
      padding-left: 2rem;
      &:before {
        margin-right: 1rem;
        content: "\e92f";
        font-family: "icomoon";
        position: absolute;
        left: 0;
        color: $secondary;
      }
      &:not(:last-child) {
        margin-bottom: 0.75rem;
      }
    }
  }
  &-bordered {
    li {
      padding: 0.5rem 0;
      color: $gray-88;
      border-top: 1px solid $border-color;

      p {
        font-weight: 400;
        // @include rfs(14);
      }
      span {
        font-weight: 500;
        @include rfs(16);
      }
    }
  }
  &-separator {
    @extend .list;
    display: flex;
    li {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-right: 0.75rem;
        padding-right: 0.75rem;
        border-right: 1px solid $gray-80;
      }
    }
  }

  &-breadcrumb {
    display: flex;
    li {
      font-weight: 600;
      &:not(:last-child) {
        margin-right: 1rem;
        color: $primary;
        &::after {
          content: "\e925";
          font-family: "icomoon";
          margin-left: 1rem;
        }

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      &:last-child {
        @include media-breakpoint-down(sm) {
          color: $primary;
        }
      }
    }
  }
}

.pagination {
  li {
    font-weight: 400;
    @include rfs(14);
    i {
      @include rfs(14);
    }
    .page-link {
      border-radius: 0.25rem;
      padding: 0.25rem 0.5rem;
      margin-right: 0.25rem;
    }
  }
}
