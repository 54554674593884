.navbar {
  padding: 0;
  position: unset;
  align-items: unset;

  @include media-breakpoint-down(md) {
    padding: 0.5rem 0;
  }
  &-wrapper {
    position: sticky;
    background-color: $white;
    top: 0;
    z-index: 99;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);

    .dropdown {
      &:hover {
        .dropdown-menu {
          display: block;
          position: absolute;
        }
      }
      &-menu {
        a {
          &:hover {
            background-color: $primary;
            color: $white;
          }
        }
      }
    }
    a {
      &:hover {
        color: $primary-72;
      }
    }
  }

  &-top {
    @include flex(center, space-between);
    @include media-breakpoint-down(xs) {
      justify-content: center;
    }
  }

  &-home {
    position: relative;
    border-top: 1px solid $gray-88;
    padding: 0.5rem 0;
    .navbar-nav {
      align-items: center;

      @include media-breakpoint-down(lg) {
        margin-right: 0;
        background-color: white;
      }
      & > li {
        @include media-breakpoint-down(lg) {
          padding: 0 0.75rem;
        }
        &:not(:last-child) {
          margin-right: 1.5rem;

          @include media-breakpoint-down(lg) {
            margin: 0;
            margin-bottom: 1.5rem;
          }
        }
        & > a {
          &.active {
            font-weight: 600;
            position: relative;
            &:after {
              content: "";
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              height: 0.25rem;
              width: 100%;
              background-color: $primary;
              bottom: -0.5rem;
            }
          }
          display: block;
          // @include rfs(14);
          padding: 0.5rem 0;
          @include media-breakpoint-up(xl) {
            padding: 0;
          }

          @include hover {
            color: $primary;
          }
        }
      }

      .collapsed {
        &-inner-menu {
          @extend .list;

          li {
            padding: 0 0.75rem;
            margin-top: 0.75rem;
            text-align: center;
            &:not(:last-child) {
            }
          }
        }

        &-toggler {
          &[aria-expanded="true"] {
            &:after {
              content: "\e908";
            }
          }
          // margin-bottom: 1rem;
        }
      }
    }
    .search {
      &-card {
        position: absolute;
        right: 0;
        z-index: 1;
        top: 50%;
        width: 100%;
        transition: all 0.2s $time-func-2;
        transform: translateY(-200%) scale(0);
        opacity: 0;
        @include media-breakpoint-down(lg) {
          padding: 0 0.5rem;
        }
        @include media-breakpoint-down(md) {
          width: 50%;
          right: 1.5rem;
          padding: 0;
        }
        @include media-breakpoint-down(sm) {
          width: 65%;
        }
        @include media-breakpoint-down(xs) {
          left: 50%;
          transform: translateY(-200%) scale(0);
          width: 96%;
        }
        input {
          height: 50px;
          // margin: 0 1rem;

          @include media-breakpoint-down(md) {
            margin: 0;
          }
          @include media-breakpoint-down(xs) {
            height: 40px;
          }
        }
        &.toggle {
          transform: translateY(-50%) scale(1);
          opacity: 1;
          @include media-breakpoint-down(xs) {
            transform: translate(-50%, -50%) scale(1);
          }
        }
        .close {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 1rem;
          cursor: pointer;
          @include rfs(18);
        }
      }
    }
  }
  &-dropdown {
    & > h6 {
      @include rfs(14);
      display: flex;
      align-items: center;
      color: $gray-400;
      font-weight: 400;
      i {
        @include rfs(12);
        margin-right: 0.5rem;
      }
    }
  }
  &-actions {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    @include media-breakpoint-up(xl) {
      order: 3;
      margin-top: 0;
    }
  }
  &-collapse {
    flex-grow: unset;
    align-items: unset;
    position: relative;
    @include media-breakpoint-down(xl) {
      padding-left: 2rem;
    }
    @include media-breakpoint-down(lg) {
      position: absolute;
      height: 100vh;
      width: 100vw;
      top: -35px;
      bottom: 0;
      left: 0;
      overflow-y: auto;
      background: white;
      padding: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      transform: translateX(-100%);
      transition: transform 1s $time-func-2;

      &:after {
        display: none;
      }
    }
    &.show {
      transform: translateX(0);
    }
  }
  &-toggler {
    color: $gray-700;
    &.close {
      position: absolute;
      top: 1rem;
      right: 0;
      background-color: $white;
      z-index: 2;
      @include rfs(12);
    }
  }
  &-brand {
    display: flex;
    align-items: center;
    padding-right: 0.75rem;
    border-right: 2px solid $gray-88;

    img {
      height: 5rem;
    }
    @include media-breakpoint-down(md) {
      img {
        height: 3rem;
      }
    }
    h6 {
      color: $black;
      @include rfs(16);
      line-height: 1.3;
      font-weight: 600;
    }
  }
  .logo {
    &-secondary {
      // width: 100px;
      height: 56px;
      img {
        object-fit: contain;
      }
    }
  }
  &-shadow {
    // box-shadow: 0px 4px 16px rgba(224, 224, 224, 0.8);
  }
  &-expand {
    &-xl {
      justify-content: space-between;
    }
  }
  &-menus {
    @include media-breakpoint-up(xl) {
      position: relative;
    }
    @include flex(center);
    .btn-secondary {
      color: $white !important;
    }
  }
}
