html {
  -webkit-scroll-behavior: smooth;
  scroll-behavior: smooth;

  @include media-breakpoint-down(xl) {
    font-size: 87.5%;
  }
}
body {
  // max-width: 1920px;
  // margin: auto;

  &.overflowhide {
    overflow: hidden;
  }
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  main {
    flex-grow: 1;
  }

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    margin: 0;
  }
}

.container {
  @include media-breakpoint-up(md) {
    padding: 0 3rem;
  }
  &-fluid {
    @include media-breakpoint-up(lg) {
      padding: 0;
    }
  }
}

//root variables
:root {
  --sidebar-width: 375px;
  @include media-breakpoint-down(sm) {
    --sidebar-width: 320px;
  }
  --section-spacing: 10.25rem;
}
a {
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  outline: 0;
  color: inherit;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
    outline: 0;
  }
}
