.form {
  &-group {
    label {
      font-weight: 500;
      @include rfs(16);
    }
  }
  &-check {
    .form-check {
      &-label {
        color: $input-color;
        font-size: $input-font-size;
        font-weight: 400;
      }
      &-input {
        border-color: $input-border-color;
      }
    }
  }
  &-control {
    // padding: 0 1rem;
  }

  &-icon {
    position: relative;
    i,
    svg,
    span {
      position: absolute;
      right: 0.75rem;
      top: 50%;
      transform: translateY(-50%);
      @include rfs(18);
      color: $gray-48;
    }
  }
}

.custom {
  &-search {
    position: relative;
    input {
      position: relative;
      padding-right: 2rem;
    }
    > [class^="ic-"],
    [class*=" ic-"] {
      font-size: 0.875rem;
      position: absolute;
      right: 0.75rem;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }
  }

  &-radio {
    input {
      width: auto;
      height: 100%;
      appearance: none;
      outline: none;
      cursor: pointer;
      transition: all 100ms linear;
      border-radius: 0.25rem;
      border: 1px solid $primary;
      padding: 0.5rem 0.75rem;
      margin-bottom: 0.75rem;
      // font-size: 0.875rem;
      &:checked {
        background: $primary;
        color: $white;
      }
      &:not(:last-child) {
        margin-right: 0.75rem;
      }
      &::before {
        content: attr(label);
        display: inline-block;
        text-align: center;
        width: 100%;
      }
    }
  }

  &-select {
    border-radius: 0.5rem;
  }
}

.range {
  -webkit-appearance: none;
  width: 100%;
  max-width: unset !important;
  height: 6px;
  background: $gray-88;
  outline: none;
  border-radius: 0.5rem;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1.25rem;
    height: 1.25rem;
    background: $primary;
    color: $primary;
    cursor: pointer;
    border-radius: 50%;
  }

  &::-moz-range-thumb {
    appearance: none;
    width: 1.5rem;
    height: 1.5rem;
    background: $primary;
    cursor: pointer;
  }
}

.files {
  &--upload {
    position: relative;
    height: 100%;
    border: 2px dashed $gray-100;
    padding: 0.75rem;
    transition: border-color 0.25s $time-func-1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $gray-88;
    border-radius: 0.5rem;

    &:hover {
      cursor: pointer;
      border-color: $primary;

      label {
        color: $primary;
      }
    }

    label {
      color: $gray-48;
      font-size: 1rem;
      text-align: center;
      height: 100%;
      width: 100%;
      font-weight: 600;
      @include flex(center, center);
      margin-bottom: 0;
      cursor: pointer;
      [class^="ic-"],
      [class*=" ic-"] {
        font-size: 1.5rem;
      }
    }

    &-lg {
      height: 100px;
      width: 100%;
    }
  }
}

input[type="range"] {
  -webkit-appearance: none;
  display: block;
  width: 100%;
  margin: 16px 0;
  background: $gray-88;
  background-image: -webkit-gradient(
    linear,
    20% 0%,
    20% 100%,
    color-stop(0%, $primary),
    color-stop(100%, $primary)
  );
  background-image: -webkit-linear-gradient(left, $primary 0%, $primary 100%);
  background-image: -moz-linear-gradient(left, $primary 0%, $primary 100%);
  background-image: -o-linear-gradient(to right, $primary 0%, $primary 100%);
  background-image: linear-gradient(to right, $primary 0%, $primary 100%);
  background-repeat: no-repeat;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  box-shadow: none;
  background: transparent;
  border-radius: 0px;
  border: none;
}
input[type="range"]::-webkit-slider-thumb {
  box-shadow: none;
  border: 0.25rem solid $primary;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background: $primary;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6px;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: transparent;
}
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  box-shadow: none;
  background: transparent;
  border-radius: 0px;
  border: none;
}
input[type="range"]::-moz-range-thumb {
  box-shadow: none;
  border: 4px solid $primary;
  height: 16px;
  width: 16px;
  border-radius: 2px;
  background: #ffffff;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: transparent;
  border: none;
  border-radius: 0px;
  box-shadow: none;
}
input[type="range"]::-ms-fill-upper {
  background: transparent;
  border: none;
  border-radius: 0px;
  box-shadow: none;
}
input[type="range"]::-ms-thumb {
  box-shadow: none;
  border: 4px solid $primary;
  height: 16px;
  width: 16px;
  border-radius: 2px;
  background: #ffffff;
  cursor: pointer;
  height: 4px;
}
input[type="range"]:focus::-ms-fill-lower {
  background: transparent;
}
input[type="range"]:focus::-ms-fill-upper {
  background: transparent;
}
.error {
  color: $red;
}
