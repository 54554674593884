.table {
  &-01 {
    thead {
      th {
        background-color: $primary;
        color: $white;
        font-weight: 600;
        border: unset;
      }
    }
  }
}
