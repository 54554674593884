//News
.news {
  &-ticker {
    display: flex;
    align-items: center;
    padding-right: 1rem;
    max-width: 700px;
    @include media-breakpoint-down(lg) {
      max-width: 400px;
    }
    @include media-breakpoint-down(md) {
      max-width: 300px;
    }
    @include media-breakpoint-down(sm) {
      max-width: unset;
    }
    @include media-breakpoint-up(md) {
      border-right: 1px solid white;
    }

    .rolling-content {
      a {
        display: inline-block;
        position: relative;
        margin-right: 1rem;
        padding-right: 1.5rem;
        color: $white;
        &:after {
          content: "";
          height: 0.5rem;
          width: 0.5rem;
          background-color: $white;
          border-radius: 50%;
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
        }
        &:hover {
          color: $primary-72;
        }
      }
      &.shadow {
        display: none;
      }
    }
  }

  &-marquee {
    overflow: hidden;
    max-width: 500px;
    @include media-breakpoint-down(md) {
      max-width: 400px;
    }
    @include media-breakpoint-down(sm) {
      max-width: unset;
    }
  }

  &-title {
    color: white;
  }
}
