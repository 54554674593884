.messenger {
  position: fixed;
  bottom: 1.5rem;
  right: 25px;
  z-index: 2;
  background-color: $blue;
  padding: 0.75rem;
  border-radius: 50%;
  border: 1px solid white;
}

img {
  &.right {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &.left {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

.divider {
  border-bottom: 1px solid $gray-88;
  &-right {
    border-right: 1px solid $gray-100;
    @include media-breakpoint-down(md) {
      border-right: none;
    }
    &.light {
      border-right: 1px solid rgba(255, 255, 255, 0.12);
      @include media-breakpoint-down(md) {
        border-right: none;
      }
    }
  }
}

.media-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &.zoom-pinch {
    max-height: 20rem;
  }
}

//-eight point spacers
// margin
@each $label, $value in $eight-pt-spacers {
  .mbottom {
    &-#{$label} {
      margin-bottom: $value !important;
    }

    &-lg {
      &-#{$label} {
        @include media-breakpoint-up(lg) {
          margin-bottom: $value !important;
        }
      }
    }

    &-xxl {
      &-#{$label} {
        @include media-breakpoint-up(xxl) {
          margin-bottom: $value !important;
        }
      }
    }
  }
  .mtop {
    &-#{$label} {
      margin-top: $value !important;
    }

    &-lg {
      &-#{$label} {
        @include media-breakpoint-up(lg) {
          margin-top: $value !important;
        }
      }
    }

    &-xxl {
      &-#{$label} {
        @include media-breakpoint-up(xxl) {
          margin-top: $value !important;
        }
      }
    }
  }
}

// margin/padding-top-bottom
@each $label, $value in $eight-pt-spacers {
  .myaxis-#{$label} {
    margin-top: $value !important;
    margin-bottom: $value !important;
  }
  .pyaxis-#{$label} {
    padding-top: $value !important;
    padding-bottom: $value !important;
  }
}
// margin/padding-right-left
@each $label, $value in $eight-pt-spacers {
  .mxaxis-#{$label} {
    margin-right: $value !important;
    margin-left: $value !important;
  }
  .pxaxis-#{$label} {
    padding-right: $value !important;
    padding-left: $value !important;
  }
}

// padding
@each $label, $value in $eight-pt-spacers {
  .pbottom {
    &-#{$label} {
      padding-bottom: $value !important;
    }

    &-lg {
      &-#{$label} {
        @include media-breakpoint-up(lg) {
          padding-bottom: $value !important;
        }
      }
    }
  }
  .ptop {
    &-#{$label} {
      padding-top: $value !important;
    }

    &-lg {
      &-#{$label} {
        @include media-breakpoint-up(lg) {
          padding-top: $value !important;
        }
      }
    }

    &-xxl {
      &-#{$label} {
        @include media-breakpoint-up(xxl) {
          padding-top: $value !important;
        }
      }
    }
  }
}

.svg {
  &-box {
    position: absolute;
    bottom: -2rem;

    @include media-breakpoint-down(md) {
      display: none;
    }
    @include media-breakpoint-up(xxxl) {
      display: none;
    }
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
    &.lg {
      width: 800px;
    }
    &.md {
      width: 600px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.icon {
  @include icon();
  &-square {
    @include icon(0.5rem, $secondary-96, 2.5rem, 1.5rem, $secondary);

    &-lg {
      @include icon(0.75rem, $secondary, 3.5rem, 2rem);
    }
    &-sm {
      @include icon(0.5rem, $secondary-96, 2rem, 1rem, $secondary);
    }
  }
}

.only {
  &-lg {
    @include media-breakpoint-up(xxxl) {
      display: none;
    }
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  &-dnone-lg {
    display: none;
    @include media-breakpoint-up(xxxl) {
      display: block;
    }
    @include media-breakpoint-down(md) {
      display: block;
    }
  }
  &-md {
    @include media-breakpoint-up(xxxl) {
      display: none;
    }
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  &-dnone-md {
    @include media-breakpoint-up(xxxl) {
      display: block;
    }
    display: none;
    @include media-breakpoint-down(sm) {
      display: block;
    }
  }
}

.hide {
  &-notice {
    top: 0;
    right: 3rem;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
  }
}

.spacing {
  &__vertical {
    > :not(:last-child) {
      margin-bottom: 1rem;
    }
  }
  &__horizontal {
    > :not(:last-child) {
      margin-right: 1rem;
    }
  }
}

.media {
  &-news {
    img {
      transition: all 0.2s ease-in-out;
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
  &-notice {
    &:hover {
      .bg-secondary {
        background-color: $primary !important;
      }
    }
  }
  &-detail {
    img {
      transition: all 0.2s ease-in-out;
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
}

.border {
  &__rounded {
    border-radius: 0.5rem;
  }
  &__pointed {
    border-radius: unset !important;
  }
}

.position-sticky {
  &.project {
    top: 140px;
  }
}
