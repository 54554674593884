.swal2-actions {
  button {
    padding: $btn-padding-y-sm $btn-padding-x-sm;
    margin-top: 0;
    margin-bottom: 0;
    &.swal2-confirm {
      background-color: $primary;
    }
    &.swal2-styled {
      @include rfs(14);
    }
  }
}
