.img {
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &-xl {
    width: 14rem;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &-lg {
    width: 12rem;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 4px;
      &.contain {
        object-fit: contain;
      }
    }
  }
  &-md {
    width: 8rem;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &-sm {
    height: 2rem;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &-404 {
    width: 445px;
    @include media-breakpoint-down(md) {
      width: 300px;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  &-landscape {
    @include aspect-ratio-box(16, 9);
    &-wide {
      @include aspect-ratio-box(21, 9);
    }
    &-02 {
      @include aspect-ratio-box(21, 7);
    }

    &.placeholder {
      svg {
        max-width: 5rem;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  &-portrait {
    @include aspect-ratio-box(4, 4);
    &-02 {
      @include aspect-ratio-box(6, 4);
    }
  }
  &-bod {
    // padding-top: 45%;
    margin-top: -45%;
    img {
      @include shape(circle, 9.25rem);
      object-fit: cover;
      object-position: top;
    }
  }
}

.avatar {
  width: 152px;
  height: 152px;
  border-radius: 50%;
  overflow: hidden;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }

  &-lg {
    @extend .avatar;
    height: 100px;
    width: 100px;
  }
}

.image--bg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  @include media-breakpoint-down(lg) {
    display: none;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @include media-breakpoint-down(sm) {
      object-position: left;
    }
  }
}
