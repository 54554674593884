.modal {
  &-bod {
    .modal {
      &-body {
        padding: 2rem;
        @include flex(center, center, column);
        text-align: center;
        .close {
          position: absolute;
          top: 0.75rem;
          right: 1rem;
        }
        .list {
          li {
            @extend .small;
            color: $gray-40;
          }
        }
        p {
          @extend .small;
          &:not(:last-child) {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
}
.modal.show .modal-dialog {
  box-shadow: none !important;
}

.modal-header .btn-close {
  display: none !important;
}
