.slider {
  &-hero {
    z-index: 1;
    overflow: hidden;
    .slick {
      &-list,
      &-track {
        // height: 100% !important;
      }

      &-dots {
        bottom: 1rem;
        li {
          margin: 0;
          button {
            &::before {
              opacity: 1;
              @include rfs(10);
              color: $gray-72;
            }
          }

          &.slick-active {
            button {
              &::before {
                color: $primary;
              }
            }
          }
        }

        @include media-breakpoint-down(lg) {
          bottom: -2.5rem;
        }
      }

      &-list {
        pointer-events: all;
      }
    }
    .slider {
      &-text {
        background-color: $primary;
        color: $white;
        padding: 0.75rem;
        text-align: center;

        @include media-breakpoint-down(sm) {
          font-size: 0.875rem;
        }
      }
    }
    .img-landscape-02 {
      @include media-breakpoint-down(sm) {
        padding-top: 9 / 16 * 100%;
      }
    }
  }
}

.slide {
  &__spacer {
    .slick {
      &-slide {
        & > div {
          padding: 0 0.75rem;
        }
      }

      &-list {
        margin: 0 -0.75rem;
      }
    }
  }
  &__height {
    .slick {
      &-track {
        display: flex !important;
      }
      &-slide {
        height: inherit !important;
      }
    }
  }
}
