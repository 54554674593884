.btn {
  @include rfs(14);
  &-scroll {
    position: fixed;
    bottom: 70px;
    right: 30px;
    z-index: 99;
    font-size: 16px;
    border: none;
    outline: none;
    background-color: $primary;
    color: white;
    cursor: pointer;
    height: 56px;
    width: 56px;
    border-radius: 50%;
    box-shadow: 0px 4px 16px rgba(black, 0.24);
    &:focus {
      outline: none;
    }
  }
}
