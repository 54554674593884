.login {
  &-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
  }
  &-card {
    width: 700px;
    overflow-x: hidden;
    overflow-y: auto;
    @include media-breakpoint-down(sm) {
      width: 500px;
    }
    @include media-breakpoint-down(xs) {
      width: 450px;
    }
  }
  &-form {
    background-color: white;
    padding: 1.75rem 2rem;
    border-radius: 0px 8px 8px 0px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.08);
    @include media-breakpoint-down(sm) {
      border-radius: 8px;
    }
    .logo {
      height: 13rem;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }
  &-button {
    margin-top: 1.5rem;
    background-color: $primary;
    border-color: $primary;
    &:hover {
      background-color: $primary;
      border-color: $primary;
    }
    // @include rfs(14px);
    &.btn {
      &-outline {
        border-color: $primary;
        background-color: white;
        color: $primary;
        margin-top: 1rem;
        &:hover {
          background-color: $primary;
          border-color: $primary;
          color: white;
        }
      }
    }
  }
  &-greeting {
    // background-color: $theme-dark;
    height: 100%;
    border-radius: 8px;
    padding: 1.5rem;
    height: 420px;

    &-lg {
      height: 520px;
    }
    &-sm {
      height: 260px;
    }
    .logo {
      // background-color: white;
      width: 13rem;
      height: 5rem;
      border-radius: 4px;
      padding: 0.5rem;
      img {
        object-fit: contain;
        height: 100%;
        width: 100%;
      }
    }
    .greeting {
      .title,
      h3 {
        // @include rfs(20px);
        font-weight: 800;
        color: rgb(0, 0, 0);
      }
      p {
        // @include rfs(14px);
        color: rgb(0, 0, 0);
        opacity: 0.86;
        line-height: 150%;
      }
    }

    .language {
      width: 21%;
      padding: 0 0.5rem 0 0;
      background-color: transparent;
      color: white;
      border: none;
      // @include rfs(14px);
      border: 0px;
      outline: 0px;
      cursor: pointer;
      &:focus {
        background-color: inherit;
        color: white;
      }
      option {
        background-color: $primary;
      }
    }
  }
  &-divider {
    border-bottom: 1px solid white;
    opacity: 0.24;
    margin: 1.5rem 0;
  }
  &-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 1.5rem;
    left: 1.5rem;
    right: 1.5rem;
    flex-wrap: wrap;
    padding-top: 1.5rem;
    color: white;
    @include rfs(0.75rem);
    span {
      display: inline-block;
      margin-right: 0.5rem;
    }
    a {
      &:hover {
        color: rgba(white, 0.8);
      }
    }
  }

  &-info {
    position: absolute;
    right: 2rem;
    bottom: 2rem;
    text-align: right;
    @include media-breakpoint-down(md) {
      display: none;
    }
    p {
      font-size: 12px;
      color: $gray-300;
      margin-bottom: 0;
    }
    img {
      height: 60px;
    }
  }
  &-lang {
    select {
      outline: none;
      border: none;
    }
  }
  &-admin {
    // background-color: $theme-light;
    .login {
      &-info {
        color: white;
        p {
          color: white;
        }
        .img-logo {
          width: 162px;
          margin-left: auto;
          img {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
}

.register {
  &-form {
    width: 600px;
    overflow-x: hidden;
    overflow-y: auto;
    @include media-breakpoint-down(sm) {
      width: auto;
      margin: 0.5rem 1rem;
    }
  }
}

.country-list{
  font-size: 20px;
  font-weight: bold;
}