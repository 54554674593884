.accordion {
  &-01 {
    .accordion {
      &-item {
        h5 {
          color: $primary;
          position: relative;
          padding-right: 2.5rem;
          cursor: pointer;
          font-family: $font-family-base;
          font-weight: 500;
          &:after {
            content: "\e926";
            font-family: "icomoon";
            position: absolute;
            color: $secondary;
            transform: translateY(-50%);
            @include rfs(20);
            top: 50%;
            right: 0;
          }
          &[aria-expanded="false"] {
            &:after {
              content: "\e923";
            }
          }
        }

        &:not(:last-child) {
          margin-bottom: 1.5rem;
          border-bottom: 1px solid $gray-88;
          padding-bottom: 1.5rem;
        }
      }
    }
  }
}
