h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: $gray-16;
  font-weight: 600;
  line-height: 130%;
  font-family: $font-family-header;
}

h1,
.h1 {
  @include rfs(48);
}
h2,
.h2 {
  @include rfs(40);
}
h3,
.h3 {
  @include rfs(32);
}

h4,
.h4 {
  @include rfs(24);
}
h5,
.h5 {
  @include rfs(20);
}
h6,
.h6 {
  @include rfs(18);
}

p,
.p {
  margin: 0;
  font-weight: 400;
  @include rfs(16);
  line-height: 150%;
  font-family: $font-family-base;
}

.small {
  @include rfs(14);
}

// Font weight
@each $label, $value in $font-weight {
  .font-#{$label} {
    font-weight: #{$value};
  }
}

.clamp {
  @for $i from 1 through 5 {
    &__#{$i} {
      @include clamp($i);
    }
  }
}

.heading {
  &__line {
    position: relative;
    &::after {
      content: "";
      width: 2.5rem;
      height: 3px;
      border-radius: 0.25rem;
      background-color: $secondary;
      position: absolute;
      top: calc(100% + 0.5rem);
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &-02 {
      @extend .heading__line;
      &::after {
        width: 4.5rem;
        height: 2px;
        background-color: $white;
        left: 0;
        bottom: -1rem;
        top: unset;
        transform: unset;
      }
    }
  }
}

.textbox {
  overflow: auto;

  p,
  img,
  ul {
    margin-bottom: 1rem !important;
  }
  span {
    font-weight: 600 !important;
  }
  ul {
    @extend .list;
    @extend .list-bullets;
  }
  h6 {
    margin-bottom: 0.5rem;
  }
  img {
    width: 100%;
    max-height: 420px;
    object-fit: contain;
    @include media-breakpoint-down(sm) {
      max-height: 320px;
    }
  }
  .table {
    max-height: unset;
  }
  a {
    color: $secondary;
  }

  table {
    width: 100% !important;
    min-width: 768px;
    @extend .table-striped;
    margin-bottom: 1rem;
    th {
      background-color: $primary;
      border: 1px solid $primary;
      color: white;
      p {
        color: $white;
        font-weight: 600;
      }
    }
    td,
    th {
      padding: 0.5rem;
      text-align: center;
      // white-space: nowrap;
    }
    td {
      border: 1px solid $gray-88;
    }
  }
}
