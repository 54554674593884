@mixin scrollable($thumb: $gray-100, $track: $white) {
  overflow: auto;
  //padding-right: .5rem;

  /* width */
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $track;
    border-radius: 4px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba($thumb, 0.8);
    border-radius: 4px;
    // cursor: pointer;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $thumb;
    cursor: pointer;
  }
}

@mixin blink($time) {
  @keyframes blink {
    0% {
      opacity: 1;
    }
    25% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
  }
  animation: blink normal $time infinite ease-in-out;
}

@mixin progressBar {
  @keyframes progressBar {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  animation: progressBar 3s ease-in-out forwards;
}

//flex
@mixin flex($vertical: null, $horizontal: null, $direction: null) {
  display: flex;
  flex-direction: $direction;
  align-items: $vertical;
  justify-content: $horizontal;
}

@mixin clamp($line) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin aspect-ratio-box($width, $height, $fit: cover, $position: top) {
  height: 0;
  overflow: hidden;
  padding-top: $height / $width * 100%;
  background: white;
  position: relative;
  img,
  video,
  iframe,
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: $fit;
    object-position: $position;
  }
}

@mixin icon(
  $radius: 50%,
  $background: $primary,
  $dimension: 2.5rem,
  $icon: 1.5rem,
  $color: $white
) {
  width: $dimension;
  height: $dimension;
  background-color: $background;
  border-radius: $radius;
  @include flex(center, center);

  i,
  a,
  span {
    color: $color;
    font-size: $icon;
  }
}
@mixin shape($shape, $size, $color: null) {
  @if $shape == square {
    width: $size;
    height: $size;
    background-color: $color;
  }

  @if $shape == circle {
    width: $size;
    height: $size;
    background-color: $color;
    border-radius: 50%;
  }

  @if $shape == triangle {
    width: 0;
    height: 0;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size * 1.5 solid $color;
  }
}
