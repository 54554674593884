.splashscreen {
  position: fixed;
  background-color: white;
  width: 100%;
  height: 100%;
  bottom: 0;
  overflow: hidden;
  z-index: 999;
  &-image {
    height: calc(100% - 0.25rem);
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      @include blink(2s);
      width: 200px;
      @include media-breakpoint-down(sm) {
        width: auto;
      }
    }
  }
  .progress {
    &-bar {
      @include progressBar;
    }
  }
}
