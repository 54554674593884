.breadcrumb {
  &-container {
    .top {
      background: $primary;
      padding: 3rem 0;
      color: $white;
      @include media-breakpoint-down(sm) {
        padding: 1.5rem 0;
      }
      .flex-center-between {
        @include media-breakpoint-down(sm) {
          flex-direction: column;

          .btn {
            margin-top: 1rem;
          }
        }
      }
    }

    .bottom {
      padding: 0.75rem 0;
      border-bottom: 1px solid $gray-90;
      margin-bottom: 2.5rem;

      .breadcrumb {
        padding: 0;
        margin: 0;
        background-color: $white;

        &-item {
          position: relative;
          a {
            color: $gray-40;
          }
          &:not(:last-child) {
            margin-right: 1.5rem;
          }
          &:not(:first-child) {
            margin-left: 1rem;
            &::before {
              content: "\e925";
              font-family: icomoon;
              @include rfs(16);
              color: $gray-40;
              position: absolute;
              top: 0.125rem;
              left: -1rem;
            }
          }

          &.active {
            a {
              color: $black;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
