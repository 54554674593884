@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500;700&display=swap");

@import "./abstracts/variables";

@import "./frameworks/bootstrap";

//abstracts
@import "./abstracts/mixin";
@import "./abstracts/base";
@import "./abstracts/utilities";
@import "./abstracts/typography";

//components

@import "./layouts/login";
@import "./components/navbar";
@import "./components/list";
@import "./components/button";
@import "./components/carousel";
@import "./components/flex";
@import "./components/footer";
@import "./components/card";
@import "./components/dropdown";
@import "./components/form";
@import "./components/table";
@import "./components/image";
@import "./components/alert";
@import "./components/tab";
@import "./components/accordion";
@import "./components/animation";
@import "./components/modal";
@import "./components/breadcrumbs";
@import "./components/splashscreen";
@import "./components/newsticker";
//sections
@import "./components/section";

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@import "../../../node_modules/lightgallery/css/lg-zoom.css";
@import "../../../node_modules/lightgallery/css/lightgallery.css";
