$enable-responsive-font-sizes: true;

$font-family-base: "Inter", sans-serif;
$font-family-header: "DM Sans", sans-serif;
// $font-family-sans-serif: "Work Sans", sans-serif;
// $// font-family-heebo: "Heebo", sans-serif;

//gray
$gray-16: #292929;
$gray-24: #3d3d3d;
$gray-32: #525252;
$gray-40: #666666;
$gray-48: #7a7a7a;
$gray-56: #8f8f8f;
$gray-64: #a3a3a3;
$gray-72: #b8b8b8;
$gray-80: #cccccc;
$gray-88: #e0e0e0;
$gray-90: #e5e5e5;
$gray-92: #ebebeb;
$gray-94: #f0f0f0;
$gray-96: #f5f5f5;
$gray-98: #fafafa;

//red
$red-16: #e42329;

//green
$green-16: #ebfbf2;

//blue
$blue-16: #e6f7fe;

// brand colors
//Primary
$primary: #07964f;
$primary-40: #09c367;
$primary-48: #0bea7b;
$primary-72: #77f9b8;
$primary-88: #c5fce1;
$primary-94: #e2fef0;

//Secondary
$secondary: #1394d3;
$secondary-96: #ecf8fd;

$theme-colors: (
  "primary": $primary,
  "primary-40": $primary-40,
  "primary-48": $primary-48,
  "primary-72": $primary-72,
  "primary-88": $primary-88,
  "primary-94": $primary-94,
  "secondary": $secondary,
  "secondary-96": $secondary-96,
  "gray-96": $gray-96,
  "gray-98": $gray-98,
  "gray-48": $gray-48,
  "white": white,
);

//theme colors
$primary: map-get($theme-colors, primary);
$secondary: map-get($theme-colors, secondary);

//border
$border-color: #cdcdcd;
$border-color-light: #dddddd;

// container
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px,
  xxl: 1375px,
);

//breakpoints
$grid-breakpoints: (
  xxs: 0,
  xs: 450px,
  sm: 485px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px,
);

//spacers
$eight-pt-spacers: (
  2: 0.125rem,
  4: 0.25rem,
  8: 0.5rem,
  12: 0.75rem,
  16: 1rem,
  24: 1.5rem,
  32: 2rem,
  40: 2.5rem,
  48: 3rem,
  56: 3.5rem,
  64: 4rem,
  72: 4.5rem,
  80: 5rem,
  88: 5.5rem,
);

//Input
$input-padding-y: 1rem;
$input-padding-x: 1rem;
$input-padding-y-sm: 0.5rem;
$input-padding-x-sm: 0.5rem;
$input-font-size-sm: 0.7rem;
$input-font-size: 1rem;
$input-color: $gray-16;
$input-bg: white;
$input-border-width: 1px;
$input-border-radius: 0.5rem;
$input-border-color: $gray-80;
$input-placeholder-color: $gray-56;
$input-line-height: 1;
$input-btn-padding-y-lg: 1rem;
$input-btn-padding-x-lg: 2.875rem;
$input-height: ($input-font-size) + ($input-padding-y * 2 * $input-line-height);

//custom select
$custom-select-border-radius: 0;

//Button
$btn-font-weight: 600;
$btn-padding-y: 0.75rem;
$btn-padding-x: 1.5rem;
$btn-border-radius: 0.25rem;
$btn-border-radius-sm: 1.5rem;
$btn-padding-y-sm: 0.25rem;
$btn-padding-x-sm: 0.75rem;
//Table
$table-cell-padding: 1rem;
$table-cell-padding-sm: 0.5rem;
$table-bg: transparent;
$table-accent-bg: rgba(black, 0.05);
$table-head-bg: $primary;
$table-head-color: white;

//Pagination
$pagination-border-color: white;
$pagination-border-width: 0rem;
$pagination-color: $gray-16;
$pagination-disabled-border-color: $gray-88;
// $pagination-disabled-bg: $light-100;
$pagination-disabled-color: $gray-40;
$pagination-hover-color: white;
$pagination-hover-bg: $primary;
$pagination-hover-border-color: $gray-72;
$pagination-active-bg: $primary;
$pagination-active-color: white;

//-animation
$time-func-1: cubic-bezier(0.4, 0, 0.2, 1);
$time-func-2: cubic-bezier(0.83, 0, 0.17, 1);
$time-func-3: cubic-bezier(0.65, 0, 0.35, 1); //used only while looping
$transition-base: all 0.25s $time-func-1;
$transition-fade: opacity 0.25s $time-func-1;
$input-transition: border-color 0.35s $time-func-1,
  box-shadow 0.35s $time-func-1, background-color 0.35s $time-func-1;
$btn-transition: color 0.25s $time-func-1, background-color 0.25s $time-func-1,
  border-color 0.25s $time-func-1, box-shadow 0.25s $time-func-1;
$transition-collapse: height 0.25s $time-func-1;
$transition-horizontal-collapse: width 0.25s $time-func-1;

// font weight
$font-weight: (
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  bolder: 800,
  black: 900,
);

//Progress
$progress-height: 0.25rem;

//breadcrumb
$breadcrumb-padding-y: 0.75rem;
$breadcrumb-padding-x: 1.5rem;
$breadcrumb-item-padding: 1.6875rem;
$breadcrumb-margin-bottom: 0;
$breadcrumb-bg: white;
$breadcrumb-divider-color: $gray-88;
$breadcrumb-active-color: $primary;
$breadcrumb-divider: ">";
$breadcrumb-border-radius: 0px;

//Nav-tabs
$nav-link-padding-y: 0.5rem;
$nav-link-padding-x: 0.75rem;
$nav-tabs-border-color: $gray-88;
$nav-tabs-border-width: 0px;
$nav-tabs-border-radius: 0.25rem;
$nav-tabs-link-hover-border-color: $gray-80;
$nav-tabs-link-active-color: white;
$nav-tabs-link-active-bg: $primary;
$nav-tabs-link-active-border-color: $gray-80;
$nav-pills-link-active-color: white;
$nav-pills-link-active-bg: $primary;
