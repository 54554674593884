.footer {
  a {
    &:hover {
      color: $primary-72;
    }
  }
  .list {
    li {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
  .form-control {
    border-radius: 1.5rem;
  }
  .btn-primary {
    z-index: 99;
  }

  .logo {
    padding: 0.25rem;
    background-color: $white;
    max-width: 200px;
  }
}
